import axios from "axios";
import Swal from "sweetalert2";
import router from "../store";
import {getBaseUrl} from "./misc";
import store from "../store";
// import Vue from "vue";

import app from "../main"

export default {
    install(Vue) {

        axios.defaults.baseURL = getBaseUrl();
        console.log("AXIOS BASE URL IS " + axios.defaults.baseURL);

        axios.interceptors.request.use(
            (request) => {
                store.set("loading", true);
                console.log("HTTP REQUEST TO URL:" + request.url);
                console.log("HTTP REQUEST DATA:", request.data);
                return request;
            },
            (err) => {
                return Promise.reject(err);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                console.log("HTTP RESPONSE DATA:", response.data);
                store.set("loading", false);
                return response;
            },
            (error) => {
                store.set("loading", false);

                console.log(
                    "HTTP ERROR DATA:",
                    error.response ? error.response.data : "<EMPTY>"
                );
                return error;
            }
        );

        // vue = Vue;
        Vue.prototype.$connector = this;
    },
    showPopup(title, text, icon = "error", callback = () => {
    }) {

/*
        console.log("TOASTR", app.$toast )

        Swal.fire({
            title: title,
            html: text,
            icon: icon,
            confirmButtonText: "ຕົກລົງ",
            heightAuto: false,
        }).then(() => {
            callback()
        });
*/

        const message = title ?? message;
        console.log({ message })

        app.$toast(message, {
            type: icon,
            // all of other options may go here
        });
        callback();
    },
    showError(title = "ເກີດຂໍ້ຜິດພາດ", err) {
        console.log("ERRORRR", err)
        let message = "";
        if (typeof err === "string") {
            message = err;
        } else if (
            err &&
            err.response &&
            err.response &&
            err.response.data &&
            err.response.data.message
        ) {
            message = err.response.data.message;
        } else if (err && err.message) {
            message = err.message;
        } else if (err && err.stack) {
            message = err.stack;
        } else {
            message = "ມີຂໍ້ຜິດພາດ";
        }
        this.showPopup(title, message, "error");
    },
    requestQrByOnePay(qr) {
        return this.request({
            command: "generateqr", qr
        });
    },
    getMerchantInfo(mcid) {
        return this.request({
            command: "getmerchantinfo", mcid
        });
    },
    requestQrByForm(form) {
        return this.request({
            command: "generateqr", ...form
        });
    },
    alert(data, icon = "error") {
        const containsHTML = (str) => {
            return /<[a-z][\s\S]*>/i.test(str);
        };

        const removePhpErrorDetails = (errorMessage) => {
            if (
                !errorMessage ||
                typeof errorMessage !== "string"
            )
                return "ມີບາງຢ່າງຜິດພາດ";
            if (errorMessage.includes(" in ")) {
                console.log("INCLUDES IN");
                const splitInWord = errorMessage.split(" in ");
                console.log({splitInWord});
                return splitInWord[0]
                    .replace(" RespondFailException", "")
                    .replace("Exception", "")
                    .replace(" :", ":");
            }
            return errorMessage;
        };

        let title = null;
        let message = null;

        if (typeof data === "string" && !containsHTML(data))
            message = removePhpErrorDetails(data);

        console.log(data);

        console.log("CONTAINS HTML", containsHTML(data));
        if (typeof data === "object") {
            if (data && data.title && !containsHTML(data.title))
                title = removePhpErrorDetails(data.title);
            if (data && data.message && !containsHTML(data.message)) {
                message = removePhpErrorDetails(data.message);
            }
        }

        if (!title && !message) {
            title = "ມີບາງຢ່າງຜິດພາດ";
        }

        this.showPopup(title, message, icon);
    },
    showLoading(title, text, dismissible = true) {
        Swal.fire({
            heightAuto: false,
            icon: "info",
            title: title,
            text: text,
            timerProgressBar: true,
            onBeforeOpen() {
                Swal.showLoading();
            },
            allowOutsideClick: dismissible,
            allowEscapeKey: dismissible,
            confirmButtonText: "ຕົກລົງ",
        }).then(() => {
        });
    },
    hideLoading() {
        Swal.hideLoading();
        Swal.close();
    },
    request(data, showError = true, header = {}) {
        const url = "/index.php";
        return new Promise((resolve, reject) => {
            let requestPromise = data
                ? axios.post(url, data, {withCredentials: true, header})
                : axios.get(url, {withCredentials: true});

            console.log({withCredentials: true, header});


            requestPromise
                .then((res) => {
                    // TODO: Handle Error Here
                    if (res.data.result !== 0) throw res;
                    else return resolve(res.data);
                })
                .catch((error) => {
                    if (!error.data)
                        error.data = {
                            result: "CANNOT_CONNECT",
                            message: "ບໍ່ສາມາດເຊື່ອມຕໍ່ Internet",
                        };

                    if (error.data.result === "LOGIN_REQUIRED") {
                        Swal.hideLoading();
                        Swal.close();
                        router.replace({
                            name: "LoginWithDestination",
                            params: {then: router.currentRoute.path},
                        });
                    } else {
                        if (showError) {
                            console.log({showError});
                            this.showError(
                                error.data.title ?? "ມີບາງຢ່າງຜິດພາດ",
                                error.data.message
                            );
                        }
                    }
                    reject(error.data);
                });
        });
    },


    // login(username, password) {
    //     return this.request({
    //         request: {command: "login", username, password},
    //     });
    // },
    // checkAuth() {
    //     this.request({request: {command: "checkauth"}}).then((r) =>
    //         vue.prototype.$user.saveSession(r)
    //     );
    // },
    // logout() {
    //     return this.request({request: {command: "logout"}});
    // },
};
