import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./scss/main.scss"
import VueSweetalert2 from 'vue-sweetalert2';
import VueQrcodeReader from "vue-qrcode-reader";

import Connector from "./utils/connector"
import Cleave from 'vue-cleave-component';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Toast, {
  position: 'bottom-right',
  hideProgressBar: true,
});

Vue.use(VueQrcodeReader);
Vue.use(Connector);
Vue.use(Cleave)

// Vue.use(VueToast)

Vue.use(VueSweetalert2);

Vue.config.productionTip = false


export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
