export const getBaseUrl = () => {
    if (process.env.NODE_ENV === "production")
        return process.env.VUE_APP_CORE_URL;
    else return "https://10.0.19.149/covidservice.php/";
}

export const support = (() => {
    if (!window.DOMParser) return false;
    const parser = new DOMParser();
    try {
        parser.parseFromString('x', 'text/html');
    } catch(err) {
        return false;
    }
    return true;
})();

export const parseEmvQrLine = (qrcode) => {
    const res = {};
    const qrLength = qrcode.length;
    let pos = 0;
    try {
        while (pos < qrLength) {
            const field = parseInt(qrcode.substring(pos, pos + 2));
            const length = parseInt(qrcode.substring(pos + 2, pos + 4));
            res[field] = qrcode.substring(pos + 4, pos + 4 + length);
            pos += 4 + length;
        }
    } catch (error) {
        console.log(error);
    }
    return res;
}
