import Vue from 'vue'
import Vuex from 'vuex'
import pathify from "./pathify";
import {make} from "vuex-pathify";

import createPersistedState from "vuex-persistedstate";

import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);

const state = {
  loading: false,
  imageUrl: ""
}

const getters = {};

const mutations = make.mutations(state);
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  getters,
  plugins: [
    pathify.plugin,
    createPersistedState({key: "merchant-check-in", storage: window.localStorage}),
  ],
})
